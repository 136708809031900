<template>
  <div>
    <el-dialog title="编辑道路组" :visible.sync="dialogVisible" width="1120px">
      <el-form label-width="100px" :rules="rules" :model="formInline" ref="form">
        <el-form-item label="道路组名称:" prop="roadGroupName">
          <el-input
            style="width: 265px;"
            size="12"
            :maxlength="20"
            v-model.trim="formInline.roadGroupName"
            placeholder="请输入道路组名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="连续计费:" class="contionus">
          <el-switch
            :disabled="true"
            v-model="formInline.continueType"
            :active-value="1"
            :inactive-value="0"
            @change="onChange"
          >
          </el-switch>
          <span class="tip" v-if="formInline.continueType">时间间隔</span>
          <el-input-number
            v-if="formInline.continueType"
            :disabled="true"
            style="width: 120px"
            controls-position="right"
            :min="0"
            :max="60"
            :step="1"
            :step-strictly="true"
            v-model.trim="formInline.intervalTime"
          >
          </el-input-number>
          <span class="tip" v-if="formInline.continueType">分钟</span>
        </el-form-item>
        <el-form-item label="已选道路:">
          <span class="totalTip">共{{ tableData.length }}条</span>
          <el-button type="primary" @click="onAddRoad()">{{ $t('button.addto') }}</el-button>
        </el-form-item>
        <el-form-item>
          <!--列表区域-->
          <div class="tableWrapper bgFFF paddingB10">
            <el-table
              header-cell-class-name="header-call-style"
              border
              v-loading="loading"
              stripe
              :data="tableData"
              style="width: 100%; font-size: 12px"
            >
              <el-table-column align="center" type="index" width="50"> </el-table-column>
              <el-table-column
                :prop="item.prop"
                :label="item.label"
                :width="item.width"
                align="center"
                v-for="item in tableCols"
                :key="item.prop"
                :formatter="item.formatter"
              ></el-table-column>
              <el-table-column label="操作" width="140" align="center">
                <template slot-scope="{ row, $index }">
                  <el-button type="text" @click="onDel(row.parkId, $index)">移除</el-button>
                </template>
              </el-table-column>
            </el-table>
            <!--分页器-->
            <!-- <div class="pagerWrapper">
              <div class="block">
                <el-pagination
                  @current-change="handleCurrentChange"
                  :current-page="page"
                  :page-size="pageSize"
                  layout="total, prev, pager, next, jumper"
                  :total="total"
                >
                </el-pagination>
              </div>
            </div> -->
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onHideDia">取消</el-button>
        <el-button type="primary" @click="onSubmit">确定</el-button>
      </span>
    </el-dialog>
    <RoadList ref="roadList" @onUpdateRoadList="onUpdateRoadList"></RoadList>
  </div>
</template>
<script>
import RoadList from "./roadList";
export default {
  name: "addRoadGroup",
  components: {
    RoadList,
  },
  data() {
    return {
      dialogVisible: false,
      total: 0,
      pageSize: 1000,
      page: 1,
      loading: false,
      time: 0,
      tableCols: [
        {
          prop: "parkAlias",
          label: "停道路编号",
          width: "",
        },
        {
          prop: "parkName",
          label: this.$t("list.Road_name"),
          width: "",
        },
        {
          prop: "areaName",
          label: this.$t("list.region"),
          width: "",
        },
        {
          prop: "scopeName",
          label: this.$t("list.charge_type"),
          width: "",
        },
        {
          prop: "operationName",
          label: this.$t("list.Merchant"),
          width: "",
        },
      ],
      tableData: [],
      formInline: {
        roadGroupId: "",
        roadGroupName: "",
        continueType: 0,
        parkIdList: "",
        intervalTime: 0,
      },
      rules: {
        roadGroupName: [{ required: true, message: "请输入道路名称", trigger: ["blur", "change"] }],
      },
    };
  },
  methods: {
    onChange(val) {
      if (!val) this.formInline.intervalTime = 0;
    },
    onUpdateRoadList(list) {
      // this.searchData();
      this.tableData.push(...list);
    },
    onOpen(roadGroupId) {
      this.formInline.roadGroupId = roadGroupId;
      this.searchData();
      this.queryRoadGroupInfo();
      this.dialogVisible = true;
    },
    // 删除已选道路组
    onDel(id, index) {
      if (this.tableData.length === 1) {
        this.$confirm("移除本条后，您就将删除当前道路组", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.tableData.splice(index, 1);
            this.onSubmit();
          })
          .catch(() => {});
      } else {
        this.tableData.splice(index, 1);
      }
      // --this.total;
      // this.formInline.parkIdList.push(id);
    },
    // 添加道路
    onAddRoad() {
      const list = this.tableData.map((ele) => {
        return ele.parkId;
      });
      this.$refs.roadList.onOpen(this.formInline.roadGroupId, list);
    },
    // 隐藏道路组
    onHideDia() {
      this.dialogVisible = false;
    },
    // 更新添加道路组
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.formInline.parkIdList = this.tableData
            .map((ele) => {
              return ele.parkId;
            })
            .join(",");
          this.$axios
            .post("/acb/2.0/roadGroup/addRoadGroup", {
              data: {
                ...this.formInline,
              },
            })
            .then((res) => {
              if (res.state == 0) {
                this.$emit("onUpdateRoadGroup", {
                  roadGroupName: this.formInline.roadGroupName,
                  continueType: this.formInline.continueType,
                  intervalTime: this.formInline.intervalTime,
                  delGroup: !this.tableData.length
                });
                this.dialogVisible = false;
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
            });
        }
      });
    },
    queryRoadGroupInfo() {
      this.$axios
        .post("/acb/2.0/roadGroup/getRoadGroupById", {
          data: {
            roadGroupId: this.formInline.roadGroupId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.formInline.roadGroupName = res.value.roadGroupName ?? "";
            this.formInline.continueType = res.value.continueType ?? "";
            this.formInline.intervalTime = res.value.intervalTime ?? "";
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // 搜索
    searchData() {
      this.loading = true;
      this.$axios
        .get("/acb/2.0/park/list", {
          data: {
            editRoadGroupId: this.formInline.roadGroupId,
            slaveRelations: "0,2",
            page: this.page,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            // this.total = res.value.total * 1;
            this.tableData = res.value.list;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // 分页处理
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
  },
};
</script>

<style scoped>
.pagerWrapper {
  text-align: right;
  margin-top: 28px;
  font-size: 12px;
}
/* >>> .el-form-item__content .el-input-number {
  width: 100px;
  height: 32px;
  line-height: 32px;
  display: flex;
  align-items: center;
}
>>> .el-input-number .el-input__inner {
  text-align: left;
  background-color: #ffffff;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #e5e6eb;
  box-sizing: border-box;
}
>>> .el-input-number.is-controls-right .el-input__inner {
  padding-left: 12px;
}
>>> .el-input-number.is-controls-right .el-input-number__decrease,
>>> .el-input-number.is-controls-right .el-input-number__increase {
  width: 18px;
  height: 12px;
  line-height: 12px;
  background: #f2f3f5;
  border-radius: 0px 0px 0px 0px;
  box-sizing: border-box;
  border: none;
}
>>> .el-input-number.is-controls-right .el-input-number__decrease {
  bottom: 4px;
  right: 4px;
}
>>> .el-input-number.is-controls-right .el-input-number__increase {
  top: 4px;
  right: 4px;
}
>>> .el-input-number .el-input__inner {
  height: 32px;
} */
.contionus >>> .el-form-item__content {
  display: flex;
  align-items: center;
}
.contionus >>> .el-form-item__content {
  height: 40px;
}
.tip {
  margin: 0 10px;
}
.totalTip {
  margin-right: 30px;
}
>>> .el-dialog__footer {
  text-align: center;
}
</style>
