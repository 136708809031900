<template>
  <el-dialog title="创建道路组" :visible.sync="dialogVisible" width="1100px">
    <el-form label-width="100px" :rules="rules" :model="formInline" ref="form">
      <el-form-item label="道路组名称:" prop="roadGroupName">
        <el-input
          style="width: 265px"
          size="12"
          :maxlength="20"
          v-model.trim="formInline.roadGroupName"
          placeholder="请输入道路组名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="连续计费:" class="contionus">
        <el-switch
          :disabled="true"
          v-model="formInline.continueType"
          :active-value="1"
          :inactive-value="0"
          @change="onChange"
        >
        </el-switch>
        <span class="tip" v-if="formInline.continueType">时间间隔</span>
        <el-input-number
          v-if="formInline.continueType"
          :disabled="true"
          style="width: 120px"
          controls-position="right"
          :min="0"
          :max="60"
          :step="1"
          :step-strictly="true"
          v-model.trim="formInline.intervalTime"
        >
        </el-input-number>
        <span class="tip" v-if="formInline.continueType">分钟</span>
      </el-form-item>
      <el-form-item label="选择道路:" class="roadSelect">
        <el-input
          style="width: 150px; margin-right: 10px"
          size="12"
          :maxlength="20"
          v-model.trim="queryForm.parkName"
          placeholder="道路名称"
        ></el-input>

        <a-cascader
          style="width: 150px; margin-right: 10px"
          @change="handleAreaChange"
          ref="cascader"
        ></a-cascader>
        <el-select
          v-model="queryForm.scopeId"
          placeholder="计费类区"
          style="width: 150px; margin-right: 10px"
        >
          <el-option
            :label="value.desc"
            :value="value.code + ''"
            :key="value.code"
            v-for="value in parkScope"
          ></el-option>
        </el-select>
        <el-select
          v-model.trim="queryForm.operationId"
          filterable
          placeholder="商户"
          style="width: 150px; margin-right: 10px"
        >
          <el-option
            :label="value.operationName"
            :value="value.operationId"
            :key="value.operationId"
            v-for="value in tenantList"
          ></el-option>
        </el-select>

        <el-button type="primary" @click="searchData()" :loading="loading">{{ $t('button.search') }}</el-button>
      </el-form-item>
      <el-form-item>
        <div class="total">已选择：{{ this.formInline.parkIdList.length }}&nbsp;条</div>
        <!--列表区域-->
        <div class="tableWrapper bgFFF paddingB10">
          <el-table
            header-cell-class-name="header-call-style"
            ref="multipleTable"
            border
            v-loading="loading"
            stripe
            :data="tableData"
            style="width: 100%; font-size: 12px"
            row-key="parkId"
            @selection-change="onSelectionChange"
          >
            <el-table-column :reserve-selection="true" align="center" type="selection" width="50">
            </el-table-column>
            <el-table-column
              :prop="item.prop"
              :label="item.label"
              :width="item.width"
              align="center"
              v-for="item in tableCols"
              :key="item.prop"
              :formatter="item.formatter"
            ></el-table-column>
          </el-table>
          <!--分页器-->
          <div class="pagerWrapper">
            <div class="block">
              <el-pagination
                @current-change="handleCurrentChange"
                :current-page="page"
                :page-size="pageSize"
                layout="total, prev, pager, next, jumper"
                :total="total"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onHideDia">取消</el-button>
      <el-button type="primary" @click="onSubmit">确定</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  name: "addRoadGroup",
  data() {
    return {
      parkScope: [],
      tenantList: [],
      dialogVisible: false,
      total: 0,
      pageSize: 10,
      page: 1,
      loading: false,
      tableCols: [
        {
          prop: "parkAlias",
          label: "停道路编号",
          width: "",
        },
        {
          prop: "parkName",
          label: this.$t("list.Road_name"),
          width: "",
        },
        {
          prop: "areaName",
          label: this.$t("list.region"),
          width: "",
        },
        {
          prop: "scopeName",
          label: this.$t("list.charge_type"),
          width: "",
        },
        {
          prop: "operationName",
          label: this.$t("list.Merchant"),
          width: "",
        },
      ],
      tableData: [],
      formInline: { roadGroupName: "", continueType: 0, parkIdList: [], intervalTime: 0 },
      queryForm: {
        parkName: "",
        operationId: "",
        scopeId: "",
        areaId: "",
        slaveRelations: "0,2",
        addRoadGroupId: 0,
      },
      rules: {
        roadGroupName: [{ required: true, message: "请输入道路名称", trigger: ["blur", "change"] }],
      },
    };
  },
  methods: {
    onChange(val) {
      if (!val) this.formInline.intervalTime = 0;
    },
    queryTypeList() {
      let url = "/acb/2.0/park/getAddOption";
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          let data = res.value;
          this.parkScope = data["parkScope"];
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    handleAreaChange() {
      this.queryForm.areaId = "";
      if (this.$refs.cascader) {
        this.queryForm.areaId = this.$refs.cascader.getStreetId()
          ? this.$refs.cascader.getStreetId()
          : this.$refs.cascader.getAreaId();
      }
    },
    // 选中数据
    onSelectionChange(list) {
      this.formInline.parkIdList = [];
      list.forEach((ele) => {
        this.formInline.parkIdList.push(ele.parkId);
      });
    },
    // 添加道路组
    addRoadGroup() {},
    // 更新添加道路组
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.formInline.parkIdList.length === 0) {
            this.$alert("请至少选择一条道路", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
            return;
          }
          this.$axios
            .post("/acb/2.0/roadGroup/addRoadGroup", {
              data: this.formInline,
            })
            .then((res) => {
              if (res.state == 0) {
                this.dialogVisible = false;
                this.$emit("onRefresh");
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
            });
        }
      });
    },
    // 隐藏道路组
    onHideDia() {
      this.dialogVisible = false;
    },
    onOpen() {
      if (this.$refs.cascader) {
        this.$refs.cascader.clear();
      }
      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }
      if (this.$refs.multipleTable) {
        this.$refs.multipleTable.clearSelection();
      }
      this.total = 0;
      this.pageSize = 10;
      this.page = 1;
      this.queryForm = {
        parkName: "",
        operationId: "",
        scopeId: "",
        areaId: "",
        slaveRelations: "0,2",
        addRoadGroupId: 0,
      };
      this.formInline = { roadGroupName: "", continueType: 0, parkIdList: [], intervalTime: 0 };
      this.getTenantList();
      this.queryTypeList();
      this.searchData();
      this.dialogVisible = true;
    },
    /* 商户名称的下拉框 */
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    // 搜索
    searchData() {
      this.loading = true;
      this.$axios
        .get("/acb/2.0/park/list", {
          data: {
            ...this.queryForm,
            page: this.page,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.total = res.value.total * 1;
            this.tableData = res.value.list;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // 分页处理
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
  },
};
</script>

<style scoped>
.pagerWrapper {
  text-align: right;
  margin-top: 28px;
  font-size: 12px;
}
/* >>> .el-form-item__content .el-input-number {
  width: 100px;
  height: 32px;
  line-height: 32px;
  display: flex;
  align-items: center;
}
>>> .el-input-number .el-input__inner {
  text-align: left;
  background-color: #ffffff;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #e5e6eb;
  box-sizing: border-box;
}
>>> .el-input-number.is-controls-right .el-input__inner {
  padding-left: 12px;
}
>>> .el-input-number.is-controls-right .el-input-number__decrease,
>>> .el-input-number.is-controls-right .el-input-number__increase {
  width: 18px;
  height: 12px;
  line-height: 12px;
  background: #f2f3f5;
  border-radius: 0px 0px 0px 0px;
  box-sizing: border-box;
  border: none;
}
>>> .el-input-number.is-controls-right .el-input-number__decrease {
  bottom: 4px;
  right: 4px;
}
>>> .el-input-number.is-controls-right .el-input-number__increase {
  top: 4px;
  right: 4px;
}
>>> .el-input-number .el-input__inner {
  height: 32px;
} */
.contionus >>> .el-form-item__content,
.roadSelect >>> .el-form-item__content {
  display: flex;
  align-items: center;
}
.contionus >>> .el-form-item__content {
  height: 40px;
}
.tip {
  margin: 0 10px;
}
>>> .el-dialog__footer {
  text-align: center;
}
.total {
  line-height: 20px;
  margin-bottom: 5px;
}
</style>
