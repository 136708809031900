<template>
  <div class="equipment bgFFF" v-loading="loading">
    <div class="e_content">
      <div class="ruleMessage">
        <div class="bind">
          <div class="subTitle">绑定详情</div>
          <div class="detail">
            <div class="item">
              <div class="label">规则名称</div>
              <div class="content">{{ title }}</div>
            </div>
            <div class="item">
              <div class="label">规则描述</div>
              <div class="content">
                <el-input
                  type="textarea"
                  maxlength="600"
                  :autosize="{ minRows: 10 }"
                  placeholder="请输入内容"
                  v-model="formData.descMsg"
                ></el-input>
                <div class="tips-box">此内容在车主端显示，是计费规则的文字版描述供车主参考</div>
              </div>
            </div>
            <div class="group">
              <div class="label">
                <span>绑定道路组</span
                ><img class="imgs" @click="onAdd" src="../../../assets/img/add.png" alt="" />
              </div>
              <div class="content">
                <div
                  :class="['tabRow', item.continueType === 1 ? 'tabRow34' : '']"
                  v-for="item in roadGroup"
                  :key="item.roadGroupId"
                >
                  <div class="rowItem" @click="onOpenUpdateDia(item.roadGroupId)">
                    {{ item.roadGroupName }}
                  </div>
                  <div class="rowItem">{{ item | descFilter }}</div>
                  <div class="rowItem">
                    <img
                      class="imgs"
                      @click="onDel(item.roadGroupId)"
                      src="../../../assets/img/reduce.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="rules">
          <div class="subTitle">规则详情</div>
          <div class="detail">
            <div class="list" v-for="item in tableData" :key="item.id">
              <div class="date">
                <span class="version">V{{ item.version }}</span>
                生效日期
                <span class="time">{{ item.effectiveDate }}</span>
              </div>
              <div class="box" v-html="item.descMsg"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="submit">
        <el-button type="primary" @click="onSubmit">{{ $t('button.preservation') }}</el-button>
        <el-button @click="$router.go(-1)">取消</el-button>
      </div>
    </div>
    <BindRoadGroup
      ref="bindRoadGroup"
      @onAddRoadGroup="onAddRoadGroup"
      :hasSelectRoadGroup="roadGroup"
    ></BindRoadGroup>
    <UpdateRoadGroup ref="updateRoadGroup" @onUpdateRoadGroup="onUpdateRoadGroup"></UpdateRoadGroup>
  </div>
</template>
<script>
import BindRoadGroup from "./components/bindRoadGroup";
import UpdateRoadGroup from "./components/updateRoadGroup";
export default {
  name: "bindRoadGroup",
  components: {
    BindRoadGroup,
    UpdateRoadGroup,
  },
  filters: {
    descFilter: (item) => {
      return item.continueType === 1 ? `连续计费：间隔时间${item.intervalTime}分钟` : "";
    },
  },
  data() {
    return {
      loading: false,
      roadGroup: [],
      roadGroupId: 0,
      roadGroupIndex: -1,
      formData: {
        descMsg: "",
        roadGroupIdList: "",
        chargeTopId: "",
      },
      tableData: [],
      title: "",
    };
  },
  methods: {
    onDel(roadGroupId) {
      this.roadGroup.splice(
        this.roadGroup.findIndex((ele) => {
          return ele.roadGroupId === roadGroupId;
        }),
        1
      );
    },
    onUpdateRoadGroup(roadGroupObj) {
      if (roadGroupObj.delGroup) {
        this.queryRoadGroup();
      } else {
        this.roadGroup.forEach((ele) => {
          if (ele.roadGroupId === this.roadGroupId) {
            Object.assign(ele, roadGroupObj);
          }
        });
      }
    },
    // 编辑道路组
    onOpenUpdateDia(roadGroupId) {
      this.roadGroupId = roadGroupId;
      this.$refs.updateRoadGroup.onOpen(roadGroupId);
    },
    onAddRoadGroup(list) {
      this.roadGroup = list;
    },
    // 添加道路组
    onAdd() {
      this.$refs.bindRoadGroup.onOpen(this.formData.chargeTopId);
    },
    onSubmit() {
      this.formData.roadGroupIdList = this.roadGroup
        .map((ele) => {
          return ele.roadGroupId;
        })
        .join(",");
      this.$axios
        .post("/acb/2.0/roadGroup/bindRoadGroup", {
          data: {
            ...this.formData,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.$router.go(-1);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // 查询已经绑定计费规则的道路组列表
    queryRoadGroup() {
      this.$axios
        .post("/acb/2.0/roadGroup/queryListByParam", {
          data: {
            chargeTopId: this.formData.chargeTopId,
            curChargeTopId: "",
            pageNum: 1,
            pageSize: 1000,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.roadGroup = res.value.list ?? [];
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // 搜索计费规则列表
    searchData() {
      this.loading = true;
      this.$axios
        .post("/acb/2.0/roadInEngine/queryListByTopId", {
          data: {
            topId: this.formData.chargeTopId,
            pageNum: 1,
            pageSize: 1000,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.tableData = res.value.list ?? [];
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },

    // 搜索计费规则详情
    queryRuleDetail() {
      this.$axios
        .post("/acb/2.0/roadInEngine/queryFeeList", {
          data: {
            topId: this.formData.chargeTopId,
            pageNum: 1,
            pageSize: 1,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            const ruleInfo = res.value.list[0] ?? {};
            this.title = ruleInfo.title ?? "";
            this.formData.descMsg = ruleInfo.descMsg ?? "";
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
  },
  created() {
    this.formData.chargeTopId = this.$route.query.topId;
    this.queryRuleDetail();
    this.searchData();
    this.queryRoadGroup();
  },
};
</script>
<style scoped>
.equipment .e_content {
  box-sizing: border-box;
  color: #48576a;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 56px - 60px - 21px);
}
.ruleMessage {
  flex: 1;
  display: flex;
}
.tips-box {
  color: #8c939d;
  font-size: 13px;
  line-height: 40px;
}
.bind,
.rules {
  flex: 1;
  padding: 16px 16px;
}
.subTitle {
  font-family: Source Han Sans CN, Source Han Sans CN;
  font-weight: bold;
  font-size: 16px;
  color: #303133;
  margin-bottom: 16px;
  position: relative;
  padding-left: 12px;
}
.subTitle::before {
  content: "";
  position: absolute;
  top: 1px;
  left: 0;
  width: 3px;
  height: 15px;
  background: #409eff;
}
.rules {
  position: relative;
}
.item {
  display: flex;
  margin-bottom: 16px;
}
.item:first-child {
  align-items: center;
}
.item:first-child .content {
  height: 31px;
  background: #f2f3f5;
  border-radius: 2px 2px;
  border: 1px solid #e4e7ec;
  box-sizing: border-box;
  line-height: 31px;
  padding-left: 12px;
  color: #86909c;
}
.label {
  font-family: Source Han Sans CN, Source Han Sans CN;
  font-size: 14px;
  color: #616266;
  margin-right: 26px;
}
.content {
  flex: 1;
}
.group .label {
  display: flex;
  justify-content: space-between;
  margin-right: 0px;
}
.group .content {
  margin-left: 80px;
  margin-top: 10px;
  /* min-height: calc(100vh - 564px); */
}
.tabRow,
.tabRow34 {
  display: flex;
  justify-content: space-between;
  height: 31px;
  border-radius: 5px 5px 5px 5px;
  border: 1px solid #e4e7ec;
  padding: 0 10px;
  margin-bottom: 16px;
  position: relative;
}
.tabRow34 {
  margin-bottom: 34px;
}
.rowItem {
  width: calc((100% - 100px) / 2);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* margin-bottom: 16px; */
  font-family: Source Han Sans CN, Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #86909c;
  line-height: 31px;
}
.rowItem:first-child {
  cursor: pointer;
}
.rowItem:nth-child(2) {
  position: absolute;
  top: 27px;
  left: 0;
  font-weight: 400;
  font-size: 12px;
  color: #86909c;
}
.rowItem:last-child {
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.imgs {
  width: 14px;
  height: 14px;
  cursor: pointer;
}
.label .imgs {
  margin-right: 10px;
}
.list {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}
.date,
.version {
  margin-bottom: 12px;
  font-family: Source Han Sans CN, Source Han Sans CN;
  font-size: 14px;
  color: #4e5969;
}
.time {
  color: #409eff;
}
.detail {
  padding-left: 12px;
}
.rules .detail {
  max-height: calc(100% - 32px);
  overflow-y: auto;
  min-height: 0;
  width: calc(100% - 32px);
  position: absolute;
  top: 46px;
  bottom: 0;
}
.box {
  background: #f2f3f5;
  border-radius: 2px 2px;
  border: 1px solid #e4e7ec;
  min-height: 50px;
  font-family: Source Han Sans CN, Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #4e5969;
  line-height: 20px;
  padding: 10px 10px;
  margin-right: 28px;
}
>>> .oneDesc {
  font-size: 14px;
  font-weight: 600;
  color: #303133;
  margin-bottom: 6px;
}
>>> .twoDesc {
  color: #4e5969;
  font-family: Source Han Sans CN, Source Han Sans CN;
  font-size: 14px;
  margin-bottom: 2px;
}
>>> .threeDesc {
  margin-left: 30px;
  margin-bottom: 2px;
}
>>> .forthDesc {
  margin-left: 60px;
  margin-bottom: 2px;
}
>>> .fiveDesc {
  margin-left: 30px;
  line-height: 24px;
}
.submit {
  margin: 0 auto;
  margin: 20px 20px;
  display: flex;
  justify-content: center;
}
>>> .submit .el-button--primary,
.submit .el-button--default {
  width: 96px;
}
>>> .el-textarea__inner {
  font-family: Source Han Sans CN, Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #4e5969;
  line-height: 26px;
  border: 1px solid #e4e7ec;
}
</style>
