<template>
  <div>
    <el-dialog title="选择道路组" :visible.sync="dialogVisible" width="700px">
      <el-form :inline="true">
        <el-form-item>
          <el-input
            style="width: 265px"
            size="12"
            :maxlength="20"
            v-model.trim="roadGroupNameStr"
            placeholder="输入道路组名称"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchData()" :loading="loading">{{ $t('button.search') }}</el-button>
          <el-button type="primary" @click="addRoadGroup()">创建道路组</el-button>
        </el-form-item>
        <div class="group">
          <div class="tabRow" v-for="item in roadGroup" :key="item.roadGroupId">
            <div class="rowItem">{{ item.roadGroupName }}</div>
            <div class="rowItem">{{ item | descFilter }}</div>
            <div class="rowItem" @click="onUpdateRoadGroup(item)">
              <img
                class="imgs"
                v-if="item.addType === 0"
                src="../../../../assets/img/add.png"
                alt=""
              />
              <img class="imgs" v-else src="../../../../assets/img/reduce.png" alt="" />
            </div>
          </div>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onHideDia">取消</el-button>
        <el-button type="primary" @click="onSubmit">确定</el-button>
      </span>
    </el-dialog>
    <AddRoadGroup ref="addRoadGroup" @onRefresh="onRefresh"></AddRoadGroup>
  </div>
</template>
<script>
import AddRoadGroup from "./addRoadGroup";
export default {
  name: "bindRoadGroup",
  components: {
    AddRoadGroup,
  },
  filters: {
    statusFilter: (key) => {
      const obj = {
        1: "删除",
        0: "添加",
      };
      return obj[key];
    },
    descFilter: (item) => {
      return item.continueType === 1 ? `连续计费：间隔时间${item.intervalTime}分钟` : "";
    },
  },
  props: {
    hasSelectRoadGroup: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      roadGroupNameStr: "",
      roadGroup: [],
      topId: "",
    };
  },
  methods: {
    onOpen(topId) {
      this.roadGroupNameStr = "";
      this.topId = topId;
      this.searchData();
      this.dialogVisible = true;
    },
    // 更新道路组列表
    onUpdateRoadGroup(item) {
      item.addType = item.addType === 1 ? 0 : 1;
    },
    // 添加道路组
    addRoadGroup() {
      this.$refs.addRoadGroup.onOpen();
    },
    // 更新添加道路组
    onSubmit() {
      let list = this.roadGroup.filter((ele) => {
        return ele.addType === 1;
      });
      this.$emit("onAddRoadGroup", list);
      this.dialogVisible = false;
    },
    // 隐藏道路组
    onHideDia() {
      this.dialogVisible = false;
    },
    onRefresh() {
      this.searchData();
    },
    // 搜索
    searchData() {
      this.loading = true;
      this.$axios
        .post("/acb/2.0/roadGroup/queryListByParam", {
          data: {
            roadGroupNameStr: this.roadGroupNameStr,
            chargeTopId: "",
            curChargeTopId: this.topId,
            pageNum: 1,
            pageSize: 1000,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.roadGroup = res.value.list ?? [];
            this.initRoadGroupList();
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // 初始化列表
    initRoadGroupList() {
      this.roadGroup.forEach((item) => {
        item.addType = 0;
      });
      this.hasSelectRoadGroup.forEach((ele) => {
        this.roadGroup.forEach((item) => {
          if (ele.roadGroupId === item.roadGroupId) {
            item.addType = ele.addType;
          }
        });
      });
    },
  },
  created() {},
};
</script>

<style scoped>
.tabRow {
  display: flex;
  justify-content: space-between;
}
.rowItem {
  width: calc((100% - 100px) / 2);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 16px;
  font-family: PingFang SC, PingFang SC;
}
.rowItem:first-child {
  font-weight: 400;
  font-size: 14px;
  color: #4e5969;
}
.rowItem:nth-child(2) {
  font-size: 14px;
  color: #86909c;
}
.rowItem:last-child {
  width: 100px;
  cursor: pointer;
  text-align: right;
}
.rowItem >>> .el-button {
  padding: 0;
}
>>> .el-dialog__footer {
  text-align: center;
}
.imgs {
  width: 14px;
  height: 14px;
  cursor: pointer;
}
</style>
