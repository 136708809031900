<template>
  <el-dialog title="选择道路" :visible.sync="dialogVisible" width="1000px">
    <el-form :model="queryForm">
      <el-form-item label="" class="roadSelect">
        <el-input
          style="width: 150px; margin-right: 10px"
          size="12"
          :maxlength="20"
          v-model.trim="queryForm.parkName"
          placeholder="道路名称"
        ></el-input>

        <a-cascader
          style="width: 150px; margin-right: 10px"
          @change="handleAreaChange"
          ref="cascader"
        ></a-cascader>
        <el-select
          v-model="queryForm.scopeId"
          placeholder="计费类区"
          style="width: 150px; margin-right: 10px"
        >
          <el-option
            :label="value.desc"
            :value="value.code + ''"
            :key="value.code"
            v-for="value in parkScope"
          ></el-option>
        </el-select>
        <el-select
          v-model.trim="queryForm.operationId"
          filterable
          placeholder="商户"
          style="width: 150px; margin-right: 10px"
        >
          <el-option
            :label="value.operationName"
            :value="value.operationId"
            :key="value.operationId"
            v-for="value in tenantList"
          ></el-option>
        </el-select>

        <el-button type="primary" @click="searchData()" :loading="loading">{{ $t('button.search') }}</el-button>
      </el-form-item>
      <el-form-item>
        <div class="total">已选择：{{ this.selectList.length }}&nbsp;条</div>
        <!--列表区域-->
        <div class="tableWrapper bgFFF paddingB10">
          <el-table
            header-cell-class-name="header-call-style"
            ref="multipleTable"
            border
            v-loading="loading"
            stripe
            :data="tableData"
            style="width: 100%; font-size: 12px"
            row-key="parkId"
            @selection-change="onSelectionChange"
          >
            <el-table-column :reserve-selection="true" :selectable="isSelectFn" align="center" type="selection" width="50">
            </el-table-column>
            <el-table-column
              :prop="item.prop"
              :label="item.label"
              :width="item.width"
              align="center"
              v-for="item in tableCols"
              :key="item.prop"
              :formatter="item.formatter"
            ></el-table-column>
          </el-table>
          <!--分页器-->
          <div class="pagerWrapper">
            <div class="block">
              <el-pagination
                @current-change="handleCurrentChange"
                :current-page="page"
                :page-size="pageSize"
                layout="total, prev, pager, next, jumper"
                :total="total"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onHideDia">取消</el-button>
      <el-button type="primary" @click="onSubmit">确定</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  name: "roadGroupList",
  data() {
    return {
      parkScope: [],
      tenantList: [],
      dialogVisible: false,
      total: 0,
      pageSize: 10,
      page: 1,
      loading: false,
      tableCols: [
        {
          prop: "parkAlias",
          label: "停道路编号",
          width: "",
        },
        {
          prop: "parkName",
          label: this.$t("list.Road_name"),
          width: "",
        },
        {
          prop: "areaName",
          label: this.$t("list.region"),
          width: "",
        },
        {
          prop: "scopeName",
          label: this.$t("list.charge_type"),
          width: "",
        },
        {
          prop: "operationName",
          label: this.$t("list.Merchant"),
          width: "",
        },
      ],
      tableData: [],
      use: 0,
      queryForm: {
        parkName: "",
        operationId: "",
        scopeId: "",
        areaId: "",
        slaveRelations: "0,2",
        addRoadGroupId: "",
      },
      selectList: [],
      hadSelectIdList: [],
    };
  },
  methods: {
    isSelectFn(row) {
      return !this.hadSelectIdList.includes(row.parkId);
    },
    queryTypeList() {
      let url = "/acb/2.0/park/getAddOption";
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          let data = res.value;
          this.parkScope = data["parkScope"];
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    handleAreaChange() {
      this.queryForm.areaId = "";
      if (this.$refs.cascader) {
        this.queryForm.areaId = this.$refs.cascader.getStreetId()
          ? this.$refs.cascader.getStreetId()
          : this.$refs.cascader.getAreaId();
      }
    },
    // 选中数据
    onSelectionChange(list) {
      this.selectList = list;
    },
    // 更新添加道路组
    onSubmit() {
      this.$emit("onUpdateRoadList", this.selectList);
      this.dialogVisible = false;
    },
    // 隐藏道路组
    onHideDia() {
      this.dialogVisible = false;
    },
    onOpen(roadGroupId, hadSelectIdList) {
      if (this.$refs.cascader) {
        this.$refs.cascader.clear();
      }
      if (this.$refs.multipleTable) {
        this.$refs.multipleTable.clearSelection();
      }
      this.hadSelectIdList = hadSelectIdList;
      this.total = 0;
      this.pageSize = 10;
      this.page = 1;
      this.queryForm = {
        parkName: "",
        operationId: "",
        scopeId: "",
        areaId: "",
        slaveRelations: "0,2",
        addRoadGroupId: roadGroupId,
      };
      this.selectList = [];
      this.getTenantList();
      this.queryTypeList();
      this.searchData();
      this.dialogVisible = true;
    },
    /* 商户名称的下拉框 */
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    // 搜索
    searchData() {
      this.loading = true;
      this.$axios
        .get("/acb/2.0/park/list", {
          data: {
            ...this.queryForm,
            page: this.page,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.total = res.value.total * 1;
            this.tableData = res.value.list;
            // this.hadSelectIdList.forEach((parkId) => {
            //   this.tableData.forEach((ele, index) => {
            //     if (parkId === ele.parkId) {
            //       this.$refs.multipleTable.toggleRowSelection(this.tableData[index]);
            //     }
            //   });
            // });
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // 分页处理
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
  },
};
</script>

<style scoped>
.pagerWrapper {
  text-align: right;
  margin-top: 28px;
  font-size: 12px;
}
.roadSelect >>> .el-form-item__content {
  display: flex;
  align-items: center;
}
>>> .el-dialog__footer {
  text-align: center;
}
.total {
  line-height: 20px;
  margin-bottom: 5px;
}
</style>
